// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "bootstrap"
import $ from 'jquery';
window.$ = window.jQuery = $;

import 'jquery-validation';
import messages from './validation/messages';

// import I18n from 'i18n-js/index.js.erb'
// console.log(I18n.t('hello'))

Rails.start()
Turbolinks.start()
ActiveStorage.start()

const locale = $('html').attr('lang');
if (locale) {
  $.extend($.validator.messages, messages[locale]);
}
// I18n.locale = locale;

// window.moment = extendMoment(moment);
// window.moment.locale("it")

window.add_spinner = function () {
  $('#spinner-area').removeClass('d-none');
}

window.remove_spinner = function () {
  $('#spinner-area').addClass('d-none');
}

$('body').on('click', 'input[name=payment-check]', function () {
  console.log('click');

  $('.payment-option').addClass('d-none');

  if ($('input[name=payment-check]:checked').val() === 'paypal') {
    return $('.paypal').removeClass('d-none');
  } else if ($('input[name=payment-check]:checked').val() === 'moneta') {
    return $('.moneta').removeClass('d-none');
  }
});

$(document).ready(function () {
  if ($('.wrapper').innerHeight() +150 < $(window).height() ) {
    console.log('minore');
    $('#footer').css('position', 'fixed')
  }
});